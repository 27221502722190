import styled from '@emotion/styled';

const ModalMainImageStyle = styled('div')`
    width: 100%;

    .scroll-container::-webkit-scrollbar {
        display: none;
    }
    .viewListImage {
        height: 450px;
        overflow-y: auto;
        scrollbar-width: none;
        padding: 16px;
        display: grid;
        width: 100%;
        grid-template-columns: 31% 31% 31%;
        gap: 10px;
        /* align-items: center; */
        justify-content: center;
        box-shadow: 4px rgba(156, 171, 194, 0.4);
        border-radius: 8px;
    }
    .imgChoose {
        width: 100%;
        height: 177px;
        object-fit: cover;
        border-radius: 8px;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        cursor: pointer;
    }
    .imgChoose.selected {
        border: 3px solid #12b897;
        border-radius: 8px;
        transform: scale(1.05);
    }
    .imgChoose:hover {
        transform: scale(1.05);
    }
    .error {
        width: 100%;
    }
    .footer {
        width: 100%;
        margin-top: 16px;
        display: flex;
        justify-content: end;
    }
    .btnCancel {
        border-radius: 6px;
        border: 2px solid #069;
        background: #fff;
        color: #069;
    }
    .btnCancel:hover {
        border-radius: 6px;
        border: 2px solid #069 !important;
        background: #fff !important;
        color: #069 !important;
    }
    .btnSave {
        border: 2px solid #069;
        background: #069;
        margin-left: 16px;
    }
    .btnSave:hover {
        border: 2px solid #069;
        background: #069 !important;
    }
    .groupTab {
        display: flex;
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
        align-items: center;
        margin-bottom: 10px;
    }
    .itineraryTab {
        margin-right: 0.5px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border: 2px solid #12b897;
        padding: 6px;
        border-bottom: none;
        color: #12b897;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        cursor: pointer;
    }
    .itineraryTab.focusTab {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border: 2px solid #12b897;
        padding: 6px;
        border-bottom: none;
        color: #12b897;
        transform: scale(1.05);
    }
    .itineraryTab:hover {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border: 2px solid #12b897;
        padding: 6px;
        border-bottom: none;
        color: #12b897;
        transform: scale(1.05);
    }
    .countryTab {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border: 2px solid #12b897;
        padding: 6px;
        color: #12b897;
        border-bottom: none;
        cursor: pointer;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    }
    .countryTab:hover {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border: 2px solid #12b897;
        padding: 6px;
        border-bottom: none;
        color: #12b897;
        transform: scale(1.05);
    }
    .countryTab.focusTab {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border: 2px solid #12b897;
        padding: 6px;
        border-bottom: none;
        color: #12b897;
        transform: scale(1.05);
    }
    .selectCountry {
        width: 200px;
        height: 36px;
        border: 1px solid #000;
        border-radius: 7px;
        color: #000;
    }
    .ant-select .ant-select-arrow {
        color: #000;
    }
    .ant-select-selection-placeholder{
        color: #000;
    }
    .selectCountry:hover {
        border-color: #12b897 !important;
    }
    .ant-select-selector:hover {
        border-color: #12b897 !important;
    }
    .selectCountry:focus {
        border-color: #12b897 !important;
    }
    .ant-select-selector:focus {
        border-color: #12b897 !important;
    }
    .viewSelect {
        display: flex;
        margin-left: auto;
    }
`;

export default ModalMainImageStyle;
