import { Popover, Skeleton } from 'antd';
import React, { useState } from 'react';
import { modifyURL } from 'src/const/contain';
import MainImageStyle from './style';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import ModalComponent from '../modalCustom';
import ModalChoseMainImage from './ModalChoseImage';

interface MainImageProps {
    checkEvent?: boolean;
    type?: any;
    mainImage?: string;
    imageUrlTourTheOpen?: string;
    exxternalIdImage?: string;
    externalContent?: any;
    refetch?: () => void;
    roleBtn?: (vale: any) => void;
    selectedImageOld?: any;
    refetchDataCheck?: () =>void
}

const MainImageComponent: React.FC<MainImageProps> = ({
    checkEvent,
    type,
    mainImage,
    imageUrlTourTheOpen,
    exxternalIdImage,
    externalContent,
    refetch,
    roleBtn,
    selectedImageOld,
    refetchDataCheck
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const isRole = useSelector(roleSelector);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            {isRole === 'Preview mode' ? (
                <MainImageStyle>
                    {(checkEvent === false || type === 'Standard') && (
                        <div>
                            {!mainImage ? (
                                <div className="viewMainImage">
                                    <Skeleton.Image
                                        className="imgGoflTravel"
                                        active={true}
                                        style={{ width: '100%', height: 413 }}
                                    />
                                </div>
                            ) : (
                                <div className="viewMainImage">
                                    <img
                                        alt=""
                                        className="imgGoflTravel"
                                        src={
                                            exxternalIdImage
                                                ? modifyURL(
                                                      exxternalIdImage,
                                                      1000,
                                                  )
                                                : modifyURL(mainImage, 1000)
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {(checkEvent === true || type === 'Event') && (
                        <div>
                            {!imageUrlTourTheOpen ? (
                                <div className="viewMainImage">
                                    <Skeleton.Image
                                        className="imgGoflTravel"
                                        active={true}
                                        style={{ width: '100%', height: 413 }}
                                    />
                                </div>
                            ) : (
                                <div className="viewMainImage">
                                    <img
                                        alt=""
                                        className="imgGoflTravel"
                                        src={modifyURL(
                                            imageUrlTourTheOpen,
                                            1000,
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </MainImageStyle>
            ) : (
                <MainImageStyle mode={isRole} onClick={() => showModal()}>
                    <Popover placement="rightTop" content={'Click to edit'}>
                        {(checkEvent === false || type === 'Standard') && (
                            <div>
                                {!mainImage ? (
                                    <div className="viewMainImage">
                                        <Skeleton.Image
                                            className="imgGoflTravel"
                                            active={true}
                                            style={{
                                                width: '100%',
                                                height: 413,
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className="viewMainImage">
                                        <img
                                            alt=""
                                            className="imgGoflTravel"
                                            src={
                                                exxternalIdImage
                                                    ? modifyURL(
                                                          exxternalIdImage,
                                                          1000,
                                                      )
                                                    : modifyURL(mainImage, 1000)
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {(checkEvent === true || type === 'Event') && (
                            <div>
                                {!imageUrlTourTheOpen ? (
                                    <div className="viewMainImage">
                                        <Skeleton.Image
                                            className="imgGoflTravel"
                                            active={true}
                                            style={{
                                                width: '100%',
                                                height: 413,
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className="viewMainImage">
                                        <img
                                            alt=""
                                            className="imgGoflTravel"
                                            src={modifyURL(
                                                imageUrlTourTheOpen,
                                                1000,
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </Popover>
                </MainImageStyle>
            )}
            <ModalComponent
                width={1000}
                visible={isModalVisible}
                onClose={handleClose}
                title="Choose main image"
                content={
                    <ModalChoseMainImage
                        externalContent={externalContent}
                        close={() => handleClose()}
                        refetch={refetch}
                        roleBtn={roleBtn}
                        selectedImageOld={selectedImageOld}
                        refetchDataCheck={refetchDataCheck}
                    />
                }
            />
        </div>
    );
};

export default MainImageComponent;
