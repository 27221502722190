import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo,
    faCircleUser,
    faShareNodes,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Skeleton } from 'antd';
import icons from 'src/assets/icon';
import QouteNavigateStyle, { ModalCopyLink } from './style';
import { nameView } from 'src/const/enum';
import { useHistory, useLocation } from 'react-router-dom';
import { formatCurrency } from 'src/const/contain';
import GroupPrice from 'src/components/groupSummary';
import moment from 'moment';
import YourMasterExperientComponent from 'src/components/yourMasterExperientce';
import ItineraryScrollComponent from 'src/components/itineraryLinkCroll';
import ModalComponent from 'src/components/modalCustom';
import Search from 'antd/es/input/Search';
import { toast } from 'react-toastify';
import { apiDownLoadPDF } from 'src/api/apiDownloadPDF';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import { versionIdSelector } from 'src/redux/selectors/versionIdSelecter';
import { versionQuoteSelector } from 'src/redux/selectors/versionQuoteSelecter';
interface ChildComponentProps {
    onDataChange?: (data: any) => void;
    dataJsonResConsultant?: any;
    groupData?: any;
    intinerary?: any;
    showNavigate?: boolean;
    showGroup?: boolean;
}

const QouteNavigateCompoment: React.FC<ChildComponentProps> = ({
    onDataChange,
    dataJsonResConsultant,
    groupData,
    intinerary,
    showNavigate = true,
    showGroup = false,
}) => {
    const [navigate, setNavigate] = useState<Number>(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search);
    const idQuote = queryParams.get('id');
    const type = queryParams.get('type');
    const pathname = window.location.pathname;
    const segments = pathname.split('/');
    const section = segments[2];
    // const versionId = localStorage.getItem('version_id') || '';
    const versionId = useSelector(versionIdSelector);
    const versionStatus = localStorage.getItem('version_status') || '';
    const urlContainsPDFPages = location.pathname.includes('PDFPages');
    const isRole = useSelector(roleSelector);
    const idIntro = localStorage.getItem('idIntro');

    useEffect(() => {
        const currentPath = location.pathname.split('/').filter(Boolean);
        if (currentPath[1] === 'introduction') {
            setNavigate(nameView.GOFL_TRAVEL_EXPERT);
            onDataChange?.({
                id: nameView.GOFL_TRAVEL_EXPERT,
                content: 'Introduction from your Golf Travel Expert',
            });
        }
        if (currentPath[1] === 'itineraryInDetail') {
            setNavigate(nameView.ITINERARY_IN_DETAIL);
            onDataChange?.({
                id: nameView.ITINERARY_IN_DETAIL,
                content: 'See itinerary in detail',
            });
        }
        if (currentPath[1] === 'pricingAndBooking') {
            setNavigate(nameView.PRICING_AND_BOOKING);
            onDataChange?.({
                id: nameView.PRICING_AND_BOOKING,
                content: 'Pricing and booking',
            });
        }
        if (currentPath[1] === 'importantInformation') {
            setNavigate(nameView.IMPORTANT_INFORMATION);
            onDataChange?.({
                id: nameView.IMPORTANT_INFORMATION,
                content: 'Important information',
            });
        }
        if (currentPath[1] === 'termsAndCoditions') {
            setNavigate(nameView.TERM_AND_CONDITION);
            onDataChange?.({
                id: nameView.TERM_AND_CONDITION,
                content: 'Terms & conditions',
            });
        }
    }, []);

    const clickForChangeView = (id: number, content: string, path: string) => {
        setNavigate(id);
        onDataChange?.({ id: id, content: content });
        history.replace(
            type
                ? `/quoteDetail/${path}?id=${idQuote}&type=${type}&mode=PreviewMode`
                : `/quoteDetail/${path}?id=${idQuote}`,
        );
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const priceDepositAndDiscount = groupData?.price;

    const roundedTotalPrice = Number(priceDepositAndDiscount?.totalPrice || 0);
    const roundedTotalDeposit = Number(
        priceDepositAndDiscount?.totalDeposit || 0,
    );
    const roundedTotalDiscount = Math.abs(
        Number(priceDepositAndDiscount?.totalDiscount || 0),
    );
    const totalDueday = priceDepositAndDiscount?.dueDate;
    const deposit2 = priceDepositAndDiscount?.deposit2;
    const deposit3 = priceDepositAndDiscount?.deposit3;

    const nameResConsultant = dataJsonResConsultant?.name;
    const lastName = nameResConsultant?.split(' ').slice(0, -1).join(' ');
    const avatar = dataJsonResConsultant?.image;
    const jobTitleConsultant = dataJsonResConsultant?.jobTitle;
    const versionName = useSelector(versionQuoteSelector);

    const companyName = dataJsonResConsultant?.companyName || '';
    let modifiedString = companyName?.replace('Golf ', '');
    modifiedString = modifiedString?.replace('plus', '+');
    const tourNameData = groupData?.tourName || null;
    const dbPriceGroups = groupData?.dbPriceGroups || '';
    const groups = groupData?.groups || '';
    const checkEvent = groupData?.isEvent;
    const atAtGlance = intinerary;

    const resultGroups =
        groups && typeof groups === 'object'
            ? Object?.entries(groups)
                  ?.map(([group, items]) => ({
                      group,
                      items,
                  }))
                  .sort((a, b) => a.group.localeCompare(b.group))
            : [];
    const departureDate = groupData?.departureDate || 0;

    const showPriceInBtnSecureQuote = (
        toTalPrice: any,
        deposit2: any,
        deposit3: any,
        toTalDeposit: any,
    ) => {
        if (Number(toTalPrice) === Number(toTalDeposit)) {
            return formatCurrency(toTalPrice);
        } else if (Number(toTalPrice) !== Number(toTalDeposit)) {
            return formatCurrency(toTalDeposit);
        }
    };

    const eventRedirectToPay = () => {
        window.open(
            `${process.env.REACT_APP_URL_PAYMENT}/quotes/QV${type?idQuote:idIntro}`,
            '_blank',
        );
    };

    const handleOpen = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const onCopy = () => {
        navigator.clipboard
            .writeText(
                `${process.env.REACT_APP_PUBLIC_ENVIROMENT}/quoteDetail/introduction?id=${versionId}`,
            )
            .then(() => {
                toast.success('Copied to clipboard!');
                setIsModalVisible(false);
            })
            .catch((err) => {
                toast.error('Failed to copy to clipboard. Please try again.');
                setIsModalVisible(false);
            });
    };
    const handleDownload = async () => {
        setLoadingDownload(true);
        try {
            const response = await apiDownLoadPDF({
                quoteRef: idQuote,
                version: versionName,
                url: type
                    ? `${process.env.REACT_APP_PUBLIC_ENVIROMENT}/quoteDetail/PDFPages?id=${idQuote}&type=${type}&mode=PreviewMode`
                    : `${process.env.REACT_APP_PUBLIC_ENVIROMENT}/quoteDetail/PDFPages?id=${idQuote}`,
                format: 'A2',
            });
            if (
                response &&
                response instanceof Blob &&
                response.type === 'application/pdf'
            ) {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `Quote${idIntro}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                toast.success('PDF downloaded successfully!');
                setLoadingDownload(false);
            } else {
                toast.error('Failed to download PDF');
                setLoadingDownload(false);
            }
        } catch (error) {
            toast.error('Failed to download PDF');
            setLoadingDownload(false);
        }
    };
    return (
        <QouteNavigateStyle>
            <div className="inforSeller">
                {dataJsonResConsultant ? (
                    <div className="viewinforSeller">
                        <div className="contentSeller">
                            <div>
                                “I’m {lastName} and I’ll be looking after your
                                trip.”
                            </div>
                            <br />
                            <div>
                                <div className="nameGolfer">
                                    {nameResConsultant}
                                </div>
                                {jobTitleConsultant && (
                                    <div>{jobTitleConsultant}</div>
                                )}
                                {companyName && <div>{modifiedString}</div>}
                            </div>
                        </div>
                        <div className="avatarSeller">
                            {avatar ? (
                                <div className="backgroundAvatar">
                                    <img
                                        alt=""
                                        className="avatarImage"
                                        src={avatar}
                                    />
                                </div>
                            ) : (
                                <FontAwesomeIcon
                                    className="avatar"
                                    icon={faCircleUser}
                                />
                            )}
                        </div>
                    </div>
                ) : (
                    <Skeleton
                        active={true}
                        style={{ width: '100%', marginBottom: 16 }}
                    />
                )}
                {!urlContainsPDFPages ? (
                    <Button
                        type="primary"
                        className="btnPhoneCall"
                        onClick={eventRedirectToPay}
                    >
                        Secure Quote Now for £
                        {showPriceInBtnSecureQuote(
                            roundedTotalPrice,
                            deposit2?.amount,
                            deposit3?.amount,
                            roundedTotalDeposit,
                        )}
                    </Button>
                ) : (
                    <div className="btnPhoneCall">
                        <a
                            className="btnLink"
                            href={`${
                                process.env.REACT_APP_URL_PAYMENT
                            }/quotes/QV${!type ? idIntro  : idQuote}`}
                        >
                            Secure Quote Now for £
                            {showPriceInBtnSecureQuote(
                                roundedTotalPrice,
                                deposit2?.amount,
                                deposit3?.amount,
                                roundedTotalDeposit,
                            )}
                        </a>
                    </div>
                )}
            </div>
            {showNavigate && (
                <div className="inforPayMent">
                    <div
                        className={
                            navigate === nameView.GOFL_TRAVEL_EXPERT
                                ? 'contentNavigteFocus'
                                : 'contentNavigte'
                        }
                        onClick={() =>
                            clickForChangeView(
                                nameView.GOFL_TRAVEL_EXPERT,
                                'Introduction from your Golf Travel Expert',
                                'introduction',
                            )
                        }
                    >
                        {navigate === nameView.GOFL_TRAVEL_EXPERT && (
                            <img
                                alt=""
                                className="arrowLeft"
                                src={icons.arrowLeft}
                            />
                        )}
                        <span>Introduction from your Golf Travel Expert</span>
                    </div>
                    <div
                        className={
                            navigate === nameView.ITINERARY_IN_DETAIL
                                ? 'contentNavigteFocus'
                                : 'contentNavigte'
                        }
                        onClick={() =>
                            clickForChangeView(
                                nameView.ITINERARY_IN_DETAIL,
                                'See itinerary in detail',
                                'itineraryInDetail',
                            )
                        }
                    >
                        {navigate === nameView.ITINERARY_IN_DETAIL && (
                            <img
                                alt=""
                                className="arrowLeft"
                                src={icons.arrowLeft}
                            />
                        )}

                        <span>See itinerary in detail</span>
                    </div>
                    <div
                        className={
                            navigate === nameView.PRICING_AND_BOOKING
                                ? 'contentNavigteFocus'
                                : 'contentNavigte'
                        }
                        onClick={() =>
                            clickForChangeView(
                                nameView.PRICING_AND_BOOKING,
                                'Pricing and booking',
                                'pricingAndBooking',
                            )
                        }
                    >
                        {navigate === nameView.PRICING_AND_BOOKING && (
                            <img
                                alt=""
                                className="arrowLeft"
                                src={icons.arrowLeft}
                            />
                        )}
                        <span>Pricing and booking</span>
                    </div>
                    <div
                        className={
                            navigate === nameView.IMPORTANT_INFORMATION
                                ? 'contentNavigteFocus'
                                : 'contentNavigte'
                        }
                        onClick={() =>
                            clickForChangeView(
                                nameView.IMPORTANT_INFORMATION,
                                'Important information',
                                'importantInformation',
                            )
                        }
                    >
                        {navigate === nameView.IMPORTANT_INFORMATION && (
                            <img
                                alt=""
                                className="arrowLeft"
                                src={icons.arrowLeft}
                            />
                        )}
                        <span>Important information</span>
                    </div>
                    <div
                        className={
                            navigate === nameView.TERM_AND_CONDITION
                                ? 'contentNavigteFocus'
                                : 'contentNavigte'
                        }
                        onClick={() =>
                            clickForChangeView(
                                nameView.TERM_AND_CONDITION,
                                'Terms & conditions',
                                'termsAndCoditions',
                            )
                        }
                    >
                        {navigate === nameView.TERM_AND_CONDITION && (
                            <img
                                alt=""
                                className="arrowLeft"
                                src={icons.arrowLeft}
                            />
                        )}
                        <span>Terms & conditions</span>
                    </div>
                </div>
            )}
            <div className="inforPayMent">
                <div>
                    {resultGroups.map((item, index) => {
                        const price = dbPriceGroups[item?.group] || '';
                        const NumberItemOfGroups = resultGroups?.length || 0;
                        const totalPerson = item?.items;
                        return (
                            <div key={index}>
                                <GroupPrice
                                    item={item}
                                    tourName={tourNameData}
                                    priceChange={price || ''}
                                    numOfGroups={NumberItemOfGroups}
                                    departureDate={departureDate}
                                    showGroup={showGroup || true}
                                    totalPersion={
                                        (totalPerson as { totalPerson: any })
                                            ?.totalPerson
                                    }
                                />
                            </div>
                        );
                    })}
                </div>
                <div className="price">
                    <div className="viewPrice">
                        <div className="totalPrice">Total Price</div>
                        <div className="numTotalPrice">
                            £{formatCurrency(Number(roundedTotalPrice))}
                        </div>
                    </div>
                    {(checkEvent === false || type === 'Standard') && (
                        <div>
                            {roundedTotalPrice === roundedTotalDeposit && (
                                <div className="viewPrice">
                                    <div className="totalDeposit">
                                        Deposit due now
                                    </div>
                                    <div className="numTotalDeposit">
                                        £
                                        {formatCurrency(
                                            Number(roundedTotalDeposit),
                                        )}
                                    </div>
                                </div>
                            )}
                            {roundedTotalPrice !== roundedTotalDeposit &&
                                Number(deposit2?.amount) !== 0 && (
                                    <>
                                        <div className="viewPrice">
                                            <div className="totalDeposit">
                                                Deposit due{' '}
                                                {moment(
                                                    deposit2?.dueDate,
                                                    'DD/MM/YYYY',
                                                ).format('DD/MM/YYYY')}
                                            </div>
                                            <div className="numTotalDeposit">
                                                £
                                                {formatCurrency(
                                                    Number(deposit2?.amount),
                                                )}
                                            </div>
                                        </div>
                                        <div className="viewPrice">
                                            <div className="totalDeposit">
                                                Deposit due now
                                            </div>
                                            <div className="numTotalDeposit">
                                                £
                                                {formatCurrency(
                                                    Number(roundedTotalDeposit),
                                                )}
                                            </div>
                                        </div>
                                        <div className="viewPrice">
                                            <div className="totalDeposit">
                                                Balance Due Date
                                            </div>
                                            <div className="numTotalDeposit">
                                                {moment(
                                                    totalDueday,
                                                    'DD/MM/YYYY',
                                                ).utc().format('DD/MM/YYYY')}
                                            </div>
                                        </div>
                                    </>
                                )}
                            {roundedTotalPrice !== roundedTotalDeposit &&
                                Number(deposit2?.amount) === 0 && (
                                    <>
                                        <div className="viewPrice">
                                            <div className="totalDeposit">
                                                Deposit due now
                                            </div>
                                            <div className="numTotalDeposit">
                                                £
                                                {formatCurrency(
                                                    Number(roundedTotalDeposit),
                                                )}
                                            </div>
                                        </div>

                                        <div className="viewPrice">
                                            <div className="totalDeposit">
                                                Balance Due Date
                                            </div>
                                            <div className="numTotalDeposit">
                                                {moment(
                                                    totalDueday,
                                                    'DD/MM/YYYY',
                                                ).utc().format('DD/MM/YYYY')}
                                            </div>
                                        </div>
                                    </>
                                )}
                        </div>
                    )}
                    {(checkEvent === true || type === 'Event') && (
                        <div>
                            {Number(roundedTotalDeposit) !==
                                Number(roundedTotalPrice) && (
                                <div className="viewPrice">
                                    <div className="totalDeposit">
                                        Deposit due now
                                    </div>
                                    <div className="numTotalDeposit">
                                        £
                                        {formatCurrency(
                                            Number(roundedTotalDeposit),
                                        )}
                                    </div>
                                </div>
                            )}
                            {Number(roundedTotalDeposit) !==
                                Number(roundedTotalPrice) && (
                                <div className="viewPrice">
                                    <div className="totalDeposit">
                                        Balance Due Date
                                    </div>
                                    <div className="numTotalDeposit">
                                        {moment(
                                            totalDueday,
                                            'DD/MM/YYYY',
                                        ).utc().format('DD/MM/YYYY')}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <>
                {(type === 'Event' || checkEvent === true) && (
                    <YourMasterExperientComponent
                        atAGlanceVersion={atAtGlance}
                    />
                )}
            </>
           
            {type === 'Event' && section === 'itineraryInDetail' && (
                <div>
                    <ItineraryScrollComponent />
                </div>
            )}
            {isRole === 'Preview mode' && (
                <div className="groupsBtn">
                    {/* MARK: download pdf event */}
                    {/* {versionStatus === 'Sent' && !urlContainsPDFPages && (
                        <Spin
                            spinning={loadingDownload}
                            delay={500}
                            className="spinDownload"
                        >
                            <Button
                                type="primary"
                                className="btnDownload"
                                ghost
                                onClick={handleDownload}
                            >
                                Download PDF{' '}
                                <FontAwesomeIcon icon={faDownload} />
                            </Button>
                        </Spin>
                    )} */}
                    {versionId && (
                        <Button
                            type="primary"
                            className="btnShare"
                            ghost
                            onClick={handleOpen}
                        >
                            Share Quote <FontAwesomeIcon icon={faShareNodes} />
                        </Button>
                    )}
                </div>
            )}
            <ModalComponent
                width={500}
                visible={isModalVisible}
                onClose={handleClose}
                title="Share your quote with your fellow golfers"
                content={
                    <ModalCopyLink>
                        <Search
                            className="inputCopy"
                            placeholder="input search text"
                            value={`${process.env.REACT_APP_PUBLIC_ENVIROMENT}/quoteDetail/introduction?id=${versionId}`}
                            readOnly
                            enterButton="Copy"
                            size="large"
                            onSearch={onCopy}
                        />
                        <div className="contenShare">
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className="faCircleInfo"
                            />
                            Anyone with the link will be able to see this quote
                        </div>
                    </ModalCopyLink>
                }
            />
        </QouteNavigateStyle>
    );
};

export default QouteNavigateCompoment;
