import React from 'react';
import IntineraryStandardStyle from './style';
import moment from 'moment';
import HotelCardComponent from 'src/components/hotelCard';
import FlightCardComponent from 'src/components/flightCard';
import CarHideCardComponent from 'src/components/carHideCard';
import TransferCard from 'src/components/tranferCard';
import GolfsCardComponent from 'src/components/golfsCard';
import TourEvenComponent from 'src/components/tourComponent';

interface intineraryProps {
    dataItinerary?: any;
    dateNumber?: any;
    lastItem?: any;
    AvatarAgent?: any;
    totalItem?: any;
    refetchApiItinarery?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    quoteTitle?: any;
    isloading?: boolean;
}

const IntineraryStandardComponent: React.FC<intineraryProps> = ({
    dataItinerary,
    dateNumber,
    lastItem,
    AvatarAgent,
    totalItem,
    refetchApiItinarery,
    roleBtnEditOnHeader,
    quoteTitle,
    isloading,
}) => {
    const date = dataItinerary?.date || null;
    const accommodations = dataItinerary?.accommodations || null;
    const flight = dataItinerary?.flights || [];
    const carHire = dataItinerary?.carHire || null;
    const transfers = dataItinerary?.transfers || null;
    const golf = dataItinerary?.golfs || null;
    const tours = dataItinerary?.tours || null;
    const totalItemData = totalItem || 0;
    const Extra = dataItinerary?.extras || [];

    const filteredArrayAirport = transfers.filter((item: any) =>
        item.carType.includes('Airport'),
    );
    const filteredArrayGolf = transfers.filter((item: any) =>
        item.carType.includes('Golf'),
    );

    //MARK: start Fillter dropOff and pickup

    const isSimilarName = (name1: string, name2: string): boolean => {
        return name2
            .toLowerCase()
            .includes(name1.split(' - ')[0].toLowerCase());
    };

    const pickupGolf: any[] = [];
    const dropoffGolf: any[] = [];

    filteredArrayGolf.forEach((t: any) => {
        let found = false;

        golf.forEach((g: any) => {
            if (isSimilarName(g.name, t.pickUp.location)) {
                if (
                    !pickupGolf.some((item) => item.id === t.id) &&
                    !dropoffGolf.some((item) => item.id === t.id)
                ) {
                    dropoffGolf.push(t);
                }
                found = true;
            } else if (isSimilarName(g.name, t.pickUp.to)) {
                if (
                    !dropoffGolf.some((item) => item.id === t.id) &&
                    !pickupGolf.some((item) => item.id === t.id)
                ) {
                    pickupGolf.push(t);
                }
                found = true;
            }
        });

        if (!found) {
            if (filteredArrayGolf.length === 1) {
                if (
                    !pickupGolf.some((item) => item.id === t.id) &&
                    !dropoffGolf.some((item) => item.id === t.id)
                ) {
                    dropoffGolf.push(t);
                }
            } else {
                const half = Math.ceil(filteredArrayGolf.length / 2);
                const firstHalf = filteredArrayGolf
                    .slice(0, half)
                    .filter(
                        (item:any) =>
                            !pickupGolf.some(
                                (pickup) => pickup.id === item.id,
                            ) &&
                            !dropoffGolf.some(
                                (dropoff) => dropoff.id === item.id,
                            ),
                    );
                const secondHalf = filteredArrayGolf
                    .slice(half)
                    .filter(
                        (item:any) =>
                            !pickupGolf.some(
                                (pickup) => pickup.id === item.id,
                            ) &&
                            !dropoffGolf.some(
                                (dropoff) => dropoff.id === item.id,
                            ),
                    );

                pickupGolf.push(...firstHalf);
                dropoffGolf.push(...secondHalf);
            }
        }
    });

    //MARK: end Fillter dropOff and pickup

    const filteredArrayHotel = transfers.filter((item: any) =>
        item.carType.includes('Hotel'),
    );
    const filteredArrayResort = transfers.filter((item: any) =>
        item.carType.includes('Resort'),
    );
    const filteredArrayOthers = transfers.filter(
        (item: any) =>
            !item.carType.includes('Airport') &&
            !item.carType.includes('Golf') &&
            !item.carType.includes('Hotel') &&
            !item.carType.includes('Resort'),
    );

    return (
        <IntineraryStandardStyle>
            <div className="titleDay1">
                {moment(date, 'YYYY-MM-D').format('dddd Do MMMM YYYY')}
            </div>
            <div
                style={{
                    display: lastItem && totalItemData > 1 ? 'flex' : 'flex',
                    flexDirection:
                        lastItem && totalItemData > 1
                            ? 'column-reverse'
                            : 'column',
                }}
            >
                {flight && (
                    <div>
                        {flight?.map((item: any, index: any) => {
                            return (
                                <div key={index}>
                                    <FlightCardComponent
                                        dataFlight={item}
                                        avatarAgent={AvatarAgent}
                                        refetchApi={refetchApiItinarery}
                                        roleBtnEditOnHeader={
                                            roleBtnEditOnHeader
                                        }
                                        quoteTitle={quoteTitle}
                                        isloading={isloading}
                                        itineraryDate={date}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
                {carHire && (
                    <div>
                        <CarHideCardComponent
                            dataCarHide={carHire}
                            lastItem={lastItem}
                            avatarAgent={AvatarAgent}
                            refetchApi={refetchApiItinarery}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                            isloading={isloading}
                            itineraryDate={date}
                        />
                    </div>
                )}
                {filteredArrayAirport?.length > 0 && (
                    <div>
                        {filteredArrayAirport?.map((item: any, index: any) => {
                            return (
                                <TransferCard
                                    dataTransfer={item}
                                    key={index}
                                    avatarAgent={AvatarAgent}
                                    refetchApi={refetchApiItinarery}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isloading}
                                    itineraryDate={date}
                                />
                            );
                        })}
                    </div>
                )}
                {accommodations?.length > 0 && (
                    <div>
                        {accommodations?.map((item: any, index: any) => {
                            return (
                                <HotelCardComponent
                                    dataHotel={item}
                                    key={index}
                                    avatarAgent={AvatarAgent}
                                    refetchApi={refetchApiItinarery}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isloading}
                                    itineraryDate={date}
                                />
                            );
                        })}
                    </div>
                )}
                {filteredArrayHotel?.length > 0 && (
                    <div>
                        {filteredArrayHotel?.map((item: any, index: any) => {
                            return (
                                <TransferCard
                                    dataTransfer={item}
                                    key={index}
                                    avatarAgent={AvatarAgent}
                                    refetchApi={refetchApiItinarery}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isloading}
                                    itineraryDate={date}
                                />
                            );
                        })}
                    </div>
                )}
                {filteredArrayResort?.length > 0 && (
                    <div>
                        {filteredArrayResort?.map((item: any, index: any) => {
                            return (
                                <TransferCard
                                    dataTransfer={item}
                                    key={index}
                                    avatarAgent={AvatarAgent}
                                    refetchApi={refetchApiItinarery}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isloading}
                                    itineraryDate={date}
                                />
                            );
                        })}
                    </div>
                )}

                {pickupGolf?.length > 0 && (
                    <div>
                        {pickupGolf?.map((item: any, index: any) => {
                            return (
                                <TransferCard
                                    dataTransfer={item}
                                    key={index}
                                    avatarAgent={AvatarAgent}
                                    refetchApi={refetchApiItinarery}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isloading}
                                    itineraryDate={date}
                                />
                            );
                        })}
                    </div>
                )}
                {golf?.length > 0 && (
                    <div>
                        {golf?.map((item: any, index: any) => {
                            return (
                                <GolfsCardComponent
                                    dataGolf={item}
                                    key={index}
                                    avatarAgent={AvatarAgent}
                                    refetchApi={refetchApiItinarery}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isloading}
                                    itineraryDate={date}
                                />
                            );
                        })}
                    </div>
                )}
                {dropoffGolf?.length > 0 && (
                    <div>
                        {dropoffGolf?.map((item: any, index: any) => {
                            return (
                                <TransferCard
                                    dataTransfer={item}
                                    key={index}
                                    avatarAgent={AvatarAgent}
                                    refetchApi={refetchApiItinarery}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isloading}
                                    itineraryDate={date}
                                />
                            );
                        })}
                    </div>
                )}
                <div>
                    {filteredArrayOthers?.length > 0 && (
                        <div>
                            {filteredArrayOthers?.map(
                                (item: any, index: any) => {
                                    return (
                                        <TransferCard
                                            dataTransfer={item}
                                            key={index}
                                            avatarAgent={AvatarAgent}
                                            refetchApi={refetchApiItinarery}
                                            roleBtnEditOnHeader={
                                                roleBtnEditOnHeader
                                            }
                                            quoteTitle={quoteTitle}
                                            isLoading={isloading}
                                            itineraryDate={date}
                                        />
                                    );
                                },
                            )}
                        </div>
                    )}
                    {tours?.length > 0 && (
                        <div className="">
                            {tours?.map((item: any, index: any) => {
                                const tourDayComments = item?.tourDayComments;
                                return (
                                    <TourEvenComponent
                                        tourData={item}
                                        tourDayComments={tourDayComments}
                                        avatarAgent={AvatarAgent}
                                        refetchApi={refetchApiItinarery}
                                        roleBtnEditOnHeader={
                                            roleBtnEditOnHeader
                                        }
                                        quoteTitle={quoteTitle}
                                        isLoading={isloading}
                                        key={index}
                                        itineraryDate={date}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {Extra?.length > 0 && (
                        <div className="dateBaneComent">
                            {Extra?.map((item: any, index: any) => {
                                const extraComemt = item?.comments;
                                return (
                                    <div key={index}>
                                        <ul>
                                            {extraComemt?.map(
                                                (item: any, index: any) => {
                                                    return (
                                                        <li key={index}>
                                                            {item?.text}
                                                        </li>
                                                    );
                                                },
                                            )}
                                        </ul>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </IntineraryStandardStyle>
    );
};

export default React.memo(IntineraryStandardComponent);
