import React, { useEffect, useState } from 'react';
import ModalConfirmStyle from './styled';
import { Button, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { newQuoteIntroSelector } from 'src/redux/selectors/editQuoteIntroSelecter';
import { newQuoteTitleSelector } from 'src/redux/selectors/editQuoteTitleSelecter';
import { apiUpdateQuote } from 'src/api/apiUpdateQuote';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EventBusService } from 'src/hooks/EventBus';
import { updatePriceSelector } from 'src/redux/selectors/updatePriceSelector';
import { contenfullSelector } from 'src/redux/selectors/contenfullSelector';
import { useMutationApi } from 'src/hooks/useApiMutation';
import { saveVersionApi } from 'src/api/apiSaveVersion';
import ModalComponent from 'src/components/modalCustom';
import { ModalCopyLink } from '../QuoteNavigate/style';
import Search from 'antd/es/input/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { updateQouteTitleSuccess } from 'src/redux/actions/quoteTitleChangeAction';
import { versionIdSelector } from 'src/redux/selectors/versionIdSelecter';
import { versionQuoteSelector } from 'src/redux/selectors/versionQuoteSelecter';

interface ModalConfirmProp {
    quoteTitle?: string;
    close?: () => void;
    roleBtn?: (state: boolean) => void;
    modeView?: string;
    updateData?: () => void;
    dataCheck?: any;
    refetchDataCheck?: () => void;
    generateLinkState?: boolean;
}

const ModalConfirmComponent: React.FC<ModalConfirmProp> = ({
    quoteTitle,
    close,
    roleBtn,
    modeView,
    updateData,
    dataCheck,
    refetchDataCheck,
    generateLinkState,
}) => {
    const [changeView, setChangeView] = useState<string>('save');
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id') || '';
    const [dataParamPrice, setDataParamPrice] = useState<any>();
    const [dataParamTitle, setDataParamTitle] = useState<any>();
    const [dataParamIntro, setDataParamIntro] = useState<any>();

    const [value, setValue] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const versionName = useSelector(versionQuoteSelector);
    const number = versionName.substring(1);
    const newQuoteTitle = useSelector(newQuoteTitleSelector);
    const newQuoteIntro = useSelector(newQuoteIntroSelector);
    const newPrice = useSelector(updatePriceSelector);
    const contenfull = useSelector(contenfullSelector);
    const checkChange = dataCheck?.changed;
    const dispatch = useDispatch();
    // const versionId = localStorage.getItem('version_id') || '';
    const versionId = useSelector(versionIdSelector);


    const handleOpen = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const onCopy = () => {
        navigator.clipboard
            .writeText(
                `${process.env.REACT_APP_PUBLIC_ENVIROMENT}/quoteDetail/introduction?id=${versionId}`,
            )
            .then(() => {
                toast.success('Copied to clipboard!');
                setIsModalVisible(false);
                refetchDataCheck?.();
            })
            .catch((err) => {
                toast.error('Failed to copy to clipboard. Please try again.');
                setIsModalVisible(false);
            });
    };
    const eventUpdateQuote = async () => {
        try {
            if (id) {
                if (dataParamPrice) {
                    await apiUpdateQuote(id, dataParamPrice);
                }
                if (dataParamTitle) {
                    await apiUpdateQuote(id, dataParamTitle);
                }
                if (dataParamIntro) {
                    await apiUpdateQuote(id, dataParamIntro);
                }
                close?.();
                toast.success('Your updates have been saved.');
                roleBtn?.(false);
                EventBusService.emit('UPDATE_DATA', {
                    data: 'New data from Component A',
                });
                updateData?.();
                refetchDataCheck?.();
                dispatch(updateQouteTitleSuccess(''));
            }
        } catch (Error) {
            toast.error(
                'An error occurred while saving your changes. Please try again later.',
            );
            close?.();
            roleBtn?.(false);
        }
    };

    const changeText = (e: any) => {
        const inputText = e.target.value;
        setValue(inputText);
    };

    const { mutate } = useMutationApi<{ param: any }, any>(
        ({ param }) => saveVersionApi(param),
        {
            onSuccess: (response) => {
                toast.success(
                    `${
                        generateLinkState
                            ? 'Quote link is generated successfully'
                            : 'Email sent successfully!'
                    }`,
                );
                setValue('');
                setIsLoading(false);
                close?.();
                updateData?.();
                refetchDataCheck?.();
                if (generateLinkState) {
                    setTimeout(() => {
                        handleOpen();
                    }, 3000);
                }
            },
            onError: (error) => {
                if (error?.message === 'Client email not found') {
                    toast.error(
                        `No client email found, please add an email address to send`,
                    );
                } else if (error?.message === 'Client not found') {
                    toast.error(
                        `No client type found on Reservations, please make sure you have linked client first before sending quote`,
                    );
                } else {
                    toast.error(error?.message);
                }
                setValue('');
                setIsLoading(false);
                close?.();
            },
        },
    );

    const saveVersionEvent = () => {
        const data = {
            quoteRef: id,
            detail: value,
            contentful: contenfull,
            skipEmail: generateLinkState,
        };
        mutate({ param: data });
        setIsLoading(true);
    };
    useEffect(() => {
        const result = newPrice.reduce((acc, item) => {
            if (
                item &&
                item.nameGroup &&
                item.price !== undefined &&
                !isNaN(item.price)
            ) {
                acc[item.nameGroup] = `${item.price}`;
            }
            return acc;
        }, {});

        if (newQuoteTitle) {
            setDataParamTitle({
                title: newQuoteTitle,
            });
        }
        if (newQuoteIntro) {
            setDataParamIntro({
                intro: newQuoteIntro,
            });
        }
        if (result) {
            setDataParamPrice({
                priceGroups: result,
            });
        }
        if (modeView) {
            setChangeView(modeView);
        }
    }, [
        quoteTitle,
        newQuoteIntro,
        newQuoteTitle,
        changeView,
        modeView,
        newPrice,
    ]);

    return (
        <ModalConfirmStyle>
            {changeView === 'save' ? (
                <div>
                    <div className="contentModal">
                        Are you sure to save the quote{' '}
                        <span className="quoteTitle">{quoteTitle}</span>
                    </div>
                    <div className="groupBtn">
                        <Button
                            type="primary"
                            className="btnEdit"
                            onClick={close}
                            ghost
                        >
                            Back to Edit
                        </Button>
                        <Button
                            type="primary"
                            className="btnSend"
                            onClick={eventUpdateQuote}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            ) : (
                <div>
                    {checkChange && (
                        <div className="contentModal">
                            Are you sure{' '}
                            {generateLinkState
                                ? 'you want to generate new version for quote:'
                                : 'you want to send the quote'}{' '}
                            <span className="quoteTitle">{quoteTitle}</span> -
                            Version
                            <span className="quoteTitle">
                                {' '}
                                {Number(number) ? Number(number) + 1 : 1}?
                            </span>
                        </div>
                    )}
                    {!checkChange && (
                        <div className="messRequire">
                            {generateLinkState
                                ? 'Unable to generate new version due to no changes on quote'
                                : 'There are no changes on this quote, do you want to send out the previous quote version instead?'}
                        </div>
                    )}
                    {checkChange && (
                        <div className="groupInput">
                            <div>Version details</div>
                            <Input
                                className="inputNew"
                                value={value}
                                placeholder="Enter your content here"
                                onChange={(e) => changeText(e)}
                            />
                        </div>
                    )}
                    <div className="groupBtn">
                        <Button
                            type="primary"
                            className="btnEdit"
                            onClick={() => {
                                close?.();
                            }}
                            ghost
                        >
                            {'Cancel'}
                        </Button>
                        <Spin spinning={isLoading} delay={500}>
                            <Button
                                type="primary"
                                className="btnSend"
                                onClick={saveVersionEvent}
                            >
                                {generateLinkState ? 'Generate' : 'Send'}
                            </Button>
                        </Spin>
                    </div>
                </div>
            )}
            <ModalComponent
                width={500}
                visible={isModalVisible}
                onClose={handleClose}
                title="Share your quote with your fellow golfers"
                content={
                    <ModalCopyLink>
                        <Search
                            className="inputCopy"
                            placeholder="input search text"
                            value={`${process.env.REACT_APP_PUBLIC_ENVIROMENT}/quoteDetail/introduction?id=${versionId}`}
                            readOnly
                            enterButton="Copy"
                            size="large"
                            onSearch={onCopy}
                        />
                        <div className="contenShare">
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className="faCircleInfo"
                            />
                            Anyone with the link will be able to see this quote
                        </div>
                    </ModalCopyLink>
                }
            />
        </ModalConfirmStyle>
    );
};

export default ModalConfirmComponent;
