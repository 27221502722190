import styled from '@emotion/styled';

interface MainImageProps {
    mode?: any;
}

const MainImageStyle = styled('div')<MainImageProps>`
    .viewMainImage {
        width: 100%;
        height: 413px;
        margin-bottom: 23px;
        border-radius: 8px;
        border: ${(props) =>
            props.mode === 'Edit mode' ? '2px solid #12b897' : 'none'};
    }
    .imgGoflTravel {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
    }
    @media screen and (max-width: 1200px) {
        .viewMainImage {
            width: 100%;
            height: auto;
            margin-bottom: 0;
            border-radius: 8px;
            border: ${(props) =>
                props.mode === 'Edit mode' ? '2px solid #12b897' : 'none'};
        }
        .imgGoflTravel {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 6px;
        }
    }
`;

export default MainImageStyle;
