import React from 'react';
import TermsAndCoditionsStyle, {
    ErrorStyle,
    TermsAndCoditionsMainImageStyle,
} from './style';
import useQueryApi from 'src/hooks/useApiQuery';
import { getTermAndCondition } from 'src/api/apiGetTermAndCondition';
import { marked } from 'marked';
import parse from 'html-react-parser';
import NotFound from 'src/components/notFoundData';
import { Skeleton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { getImageApi } from 'src/api/apiGetImage';
import { getImageContentFromExternalIdApi } from 'src/api/apiGetImageAndContentfFromExternalId';
import { ImageExternalContent } from 'src/types';
import MainImageComponent from 'src/components/mainImageComponent';

interface TermsAndCoditionsProps {
    customProp?: any;
    dataJsonProp?: any;
    image?: string;
    refetchData?: () => void;
    roleBtn?: (value: any) => void;
    refetchDataCheck?: ()=>void
}

const TermsAndCoditionsComponent: React.FC<TermsAndCoditionsProps> = ({
    customProp,
    dataJsonProp,
    image,
    refetchData,
    roleBtn,
    refetchDataCheck
}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get('type');
    const tourNameTakeImage: string = dataJsonProp?.tourName || '';
    const words = tourNameTakeImage?.split(' ');
    const firstTwoWords =
        words[0] === 'Masters' ? 'Masters' : `${words[0]} ${words[1]}`;
    //id image tour event
    const defaultImage = customProp?.defaultImage?.sys?.id;
    const idTheOpenImageInContentFullApi: any =
        customProp?.theOpenImage?.sys?.id || null;
    const idRyderCupImage: any = customProp?.theRyderCupImage?.sys?.id || null;
    const idMasterImage: any = customProp?.theMastersImage?.sys?.id || null;
    const idsolheimCupImageImage: any =
        customProp?.solheimCupImage?.sys?.id || null;
    const checkEvent = dataJsonProp?.isEvent;
    const externalContentId = dataJsonProp?.mainCourse?.externalContentId || '';
    const mainImageAfterUpdate = dataJsonProp?.mainImage || '';
    const externalContent = dataJsonProp?.externalContent || [];
    const { data: imageExternalContent } = useQueryApi(
        ['image', externalContentId],
        () => getImageContentFromExternalIdApi(externalContentId),
        {
            enabled: !!externalContentId,
        },
    );

    const exxternalIdImage = (() => {
        const content = imageExternalContent as ImageExternalContent;
        if (
            content?.fields?.cldImages &&
            Array.isArray(content.fields.cldImages) &&
            content.fields.cldImages.length > 0
        ) {
            return content.fields.cldImages[0].url;
        }
        if (
            content?.fields?.images &&
            Array.isArray(content.fields.images) &&
            content.fields.images.length > 0
        ) {
            return content.fields.images[0].url;
        }
        return '';
    })();

    const getMappedArrayByCondition = (condition: any) => {
        let theImageToure = '';
        switch (condition) {
            case 'The Open':
                theImageToure = idTheOpenImageInContentFullApi;
                break;
            case 'Ryder Cup':
                theImageToure = idRyderCupImage;
                break;
            case 'Masters':
                theImageToure = idMasterImage;
                break;
            case 'Solheim Cup':
                theImageToure = idsolheimCupImageImage;
                break;
            default:
                return (theImageToure = defaultImage);
        }
        return theImageToure;
    };
    const idImageTour = getMappedArrayByCondition(firstTwoWords);

    const { data: imageTourTheOpenData } = useQueryApi(
        ['image', idImageTour],
        () => getImageApi(idImageTour),
        {
            enabled: !!idImageTour,
        },
    );

    const imageUrlTourTheOpen = (imageTourTheOpenData as any)?.fields?.file?.url
        ? `http:${(imageTourTheOpenData as any)?.fields?.file?.url}`
        : '';

    const {
        data: dataTermAndCondition,
        isLoading,
        isError,
    } = useQueryApi(['idItinerary'], () => getTermAndCondition());

    const TermAndCondition =
        (dataTermAndCondition as any)?.fields?.body ||
        '<p>No content available.</p>';
    const htmlContent = marked(TermAndCondition);

    const renderError = () => (
        <div className="error">
            <NotFound content={<div>No data to display</div>} />
        </div>
    );

    const renderLoading = () => (
        <ErrorStyle>
            <Skeleton
                className="imgGoflTravel"
                active={true}
                style={{ width: '100%' }}
            />
        </ErrorStyle>
    );

    return (
        <>
            <TermsAndCoditionsMainImageStyle>
                <MainImageComponent
                    checkEvent={checkEvent}
                    type={type}
                    mainImage={
                        mainImageAfterUpdate ? mainImageAfterUpdate : image
                    }
                    exxternalIdImage={
                        mainImageAfterUpdate
                            ? mainImageAfterUpdate
                            : exxternalIdImage
                    }
                    imageUrlTourTheOpen={
                        mainImageAfterUpdate
                            ? mainImageAfterUpdate
                            : imageUrlTourTheOpen
                    }
                    externalContent={externalContent}
                    refetch={refetchData}
                    roleBtn={roleBtn}
                    selectedImageOld={mainImageAfterUpdate}
                    refetchDataCheck={refetchDataCheck}
                />
            </TermsAndCoditionsMainImageStyle>
            {isError ? (
                renderError()
            ) : isLoading ? (
                renderLoading()
            ) : (
                <TermsAndCoditionsStyle>
                    <div className="title">Terms & Conditions</div>
                    <div className="content">{parse(String(htmlContent))}</div>
                    <div className="content">
                        <a
                            href="https://www.yourgolftravel.com/terms-and-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link"
                        >
                            See full terms and conditions{' '}
                            <FontAwesomeIcon
                                className="iconLink"
                                icon={faArrowUpRightFromSquare}
                            />
                        </a>
                    </div>
                </TermsAndCoditionsStyle>
            )}
        </>
    );
};

export default TermsAndCoditionsComponent;
