import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import QuoteDetailPageStyle from './style';
import MobileQouteDetailPage from './mobile';
import DeskTopQuoteDetailPage from './desktop';
import { getDetailQuote } from 'src/api/apiGetDetailQuote';
import { getQuoteDetailJson } from 'src/api/apiGetQuoteDetailJson';
import { getImageApi } from 'src/api/apiGetImage';
import { useDispatch } from 'react-redux';
import { updateContenfullSuccess } from 'src/redux/actions/contenfullAction';
import useQueryApi from 'src/hooks/useApiQuery';
import { getQuoteDetailVersion } from 'src/api/apiGetQuoteDetailVersion';
import { updateClientNameSuccess } from 'src/redux/actions/clientNameAction';
import { updateVersionidSuccess } from 'src/redux/actions/versionIdState';
import { updateVersionQuoteSuccess } from 'src/redux/actions/versionQuoteAction';

const QuoteDetailPage = () => {
    const [data, setData] = useState<any>();
    const [mainImage, setMainImage] = useState<any>();
    const [dataJson, setDataJson] = useState<any>();
    const location: any = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id') || '';
    const type = queryParams.get('type');

    const { data: dataVersion } = useQueryApi(
        ['id', id],
        () => getQuoteDetailVersion(id),
        {
            enabled: !!id && !type,
        },
    );

    const dataVer = (dataVersion as { data: any })?.data;
    const contenfullDataVer = dataVer?.contentful || null;
    const introductionDataVer = dataVer?.introduction || null;
    const defaultImageIdVer = dataVer?.contentful?.defaultImage?.sys?.id;
    const atAglance = dataVer?.quoteItinerary;
    const dataInforVer = dataVer?.introduction?.version;
    if (dataInforVer) {
        localStorage.setItem('version_quote', dataInforVer?.version);
        localStorage.setItem('version_id', dataInforVer?.id);
        localStorage.setItem('version_status', dataInforVer?.status);
        dispatch(updateVersionidSuccess(dataInforVer?.id));
        dispatch(updateVersionQuoteSuccess(dataInforVer?.version));
    }

    const { data: dataImageVer } = useQueryApi(
        ['defaultImageIdVer', defaultImageIdVer],
        () => getImageApi(defaultImageIdVer),
        {
            enabled: !!defaultImageIdVer,
        },
    );
    const dataImageVerParserString =
        `http:${(dataImageVer as { fields: any })?.fields?.file?.url}` || '';

    const fetchData = useCallback(async () => {
        try {
            const [response, responseDataJson] = await Promise.all([
                getDetailQuote(),
                id ? getQuoteDetailJson(id) : Promise.resolve(null),
            ]);

            if (responseDataJson) {
                setDataJson(responseDataJson);
            } else {
                setDataJson(null);
            }
            const clientName = (
                responseDataJson as unknown as { clientName: any }
            )?.clientName;
            const version = (responseDataJson as unknown as { version: any })
                ?.version;
            setData(response?.fields);
            dispatch(updateContenfullSuccess(response?.fields));
            dispatch(updateClientNameSuccess(clientName));
            localStorage.setItem('version_quote', version?.version);
            localStorage.setItem('version_id', version?.id);
            localStorage.setItem('version_status', version?.status);
            dispatch(updateVersionidSuccess(version?.id));
            dispatch(updateVersionQuoteSuccess(version?.version||''));


            const defaultImageId = response?.fields?.defaultImage?.sys?.id;
            if (defaultImageId) {
                try {
                    const imageResponse = await getImageApi(defaultImageId);
                    setMainImage(imageResponse);
                } catch (err) {
                    setMainImage(null);
                }
            }
        } catch (err) {
            setData(null);
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (type) {
            fetchData();
        }
    }, [fetchData, type]);
    const imageIntroDuction = `http:${mainImage?.fields?.file?.url}`;

    return (
        <Router>
            <QuoteDetailPageStyle>
                <div className="deskTopView">
                    <DeskTopQuoteDetailPage
                        Imange={
                            !type ? dataImageVerParserString : imageIntroDuction
                        }
                        dataContenFull={!type ? contenfullDataVer : data}
                        dataJsonChild={!type ? introductionDataVer : dataJson}
                        onUpdate={fetchData}
                        intinerary={atAglance}
                    />
                </div>
                <div className="mobileView">
                    <MobileQouteDetailPage
                        Imange={
                            !type ? dataImageVerParserString : imageIntroDuction
                        }
                        dataContenFull={!type ? contenfullDataVer : data}
                        dataJsonChild={!type ? introductionDataVer : dataJson}
                        onUpdate={fetchData}
                        intinerary={atAglance}
                    />
                </div>
            </QuoteDetailPageStyle>
        </Router>
    );
};

export default React.memo(QuoteDetailPage);
