import React, { useEffect, useState } from 'react';
import ModalMainImageStyle from './style';
import useQueryApi from 'src/hooks/useApiQuery';
import { getListImage } from 'src/api/apiGetListImage';
import { modifyURL } from 'src/const/contain';
import NotFound from 'src/components/notFoundData';
import { Button, Select } from 'antd';
import { useMutationApi } from 'src/hooks/useApiMutation';
import { apiUpdateQuote } from 'src/api/apiUpdateQuote';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { getCountry } from 'src/api/apiGetCountry';
import { getTournamentnament } from 'src/api/apiGetTour';

interface ModalMainImageProps {
    externalContent?: any;
    close?: () => void;
    refetch?: () => void;
    roleBtn?: (value: any) => void;
    selectedListImage?: any;
    refetchDataCheck?:()=>void
}

const ModalChoseMainImage: React.FC<ModalMainImageProps> = ({
    externalContent,
    close,
    refetch,
    roleBtn,
    selectedListImage,
    refetchDataCheck
}) => {
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [tab, setTab] = useState<string>('');
    const [countryValue, setCountryValue] = useState<any>('');
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const idQuote = queryParams.get('id');
    const type = queryParams.get('type');

    const resultEntryId =
        externalContent
            ?.map((entryId: any) => `entryId=${entryId}`)
            ?.join('&') || '';

    const { data: DataMainImage, refetch: refetchMainImage } = useQueryApi(
        ['image', externalContent],
        () => {
            if (tab === 'itinareryTab') {
                return getListImage(resultEntryId);
            } else {
                return getListImage(countryValue);
            }
        },
        {
            enabled: !!externalContent && !!tab,
        },
    );

    useEffect(() => {
        setSelectedImages(selectedListImage);
        if (countryValue) {
            refetchMainImage();
        }
    }, [countryValue, refetchMainImage, selectedListImage, type]);

    const { data: DataTournament } = useQueryApi(['Tournament'], () =>
        getTournamentnament(),
    );
    const selectValueTournament = Array.isArray(DataTournament)
        ? DataTournament.map((Tour) => ({
              value: Tour.id,
              label: Tour.name,
          }))
        : [];

    const { data: DataCountry } = useQueryApi(['country'], () => getCountry());

    const selectValue = Array.isArray(DataCountry)
        ? DataCountry.map((country) => ({
              value: country.id,
              label: country.name,
          }))
        : [];

    const { mutate } = useMutationApi<{ id: any; dataParam: any }, any>(
        ({ id, dataParam }) => apiUpdateQuote(id, dataParam),
        {
            onSuccess: (response) => {
                toast.success(`Save image successfully!`);
                setSelectedImages([]);
                refetch?.();
                roleBtn?.(false);
                close?.();
                refetchDataCheck?.()
            },
            onError: (error) => {
                toast.error(`Save image false!`);
                setSelectedImages([]);
                console.log(error);
                close?.();
            },
        },
    );

    const itinareryTab = () => {
        setTab('itinareryTab');
    };

    const countryTab = () => {
        setTab('countryTab');
    };

    const chooseImage = (image: string) => {
        setSelectedImages((prevSelectedImages) => {
            if (prevSelectedImages.includes(image)) {
                return prevSelectedImages.filter((img) => img !== image);
            } else {
                return [...prevSelectedImages, image];
            }
        });
    };

    const saveEvent = () => {
        const data = {
            images: selectedImages,
        };
        // if (selectedImages?.length > 0 && idQuote) {
        mutate({ id: idQuote, dataParam: data });
        // }
    };

    const cancelBtn = () => {
        setSelectedImages([]);
        refetchMainImage();
        setTab('itinareryTab');
        close?.();
    };

    const onChange = (value: string) => {
        const paramCountry = `countryId=${value}`;
        const paramTour = `tournamentId=${value}`;

        setCountryValue(type === 'Event' ? paramTour : paramCountry);
    };

    return (
        <ModalMainImageStyle>
            <div className="groupTab">
                {type === 'Standard' && (
                    <div
                        className={`itineraryTab ${
                            tab === 'itinareryTab' ? 'focusTab' : ''
                        }`}
                        onClick={itinareryTab}
                    >
                        Itinerary Image
                    </div>
                )}
                <div
                    className={`countryTab ${
                        tab === 'countryTab' ? 'focusTab' : ''
                    }`}
                    onClick={countryTab}
                >
                    {type === 'Standard' ? 'Country Image' : 'Tournament Image'}
                </div>
                {(tab === 'countryTab' || type === 'Event') && (
                    <div className="viewSelect">
                        <Select
                            className="selectCountry"
                            placeholder={
                                type === 'Event'
                                    ? 'Select Tournament'
                                    : 'Select country'
                            }
                            optionFilterProp="label"
                            onChange={onChange}
                            options={
                                type === 'Event'
                                    ? selectValueTournament
                                    : selectValue
                            }
                        />
                    </div>
                )}
            </div>
            {tab === 'itinareryTab' ? (
                <div>
                    {Array.isArray(DataMainImage) ? (
                        <div className="viewListImage scroll-container">
                            {Array.isArray(DataMainImage) &&
                                DataMainImage.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <img
                                                alt=""
                                                className={`imgChoose ${
                                                    selectedImages.includes(
                                                        item,
                                                    )
                                                        ? 'selected'
                                                        : ''
                                                }`}
                                                src={modifyURL(item, 500)}
                                                onClick={() =>
                                                    chooseImage(item)
                                                }
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    ) : (
                        <div className="error">
                            <NotFound content={<div>No data to display</div>} />
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    {Array.isArray(DataMainImage) ? (
                        <div className="viewListImage scroll-container">
                            {Array.isArray(DataMainImage) &&
                                DataMainImage.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <img
                                                alt=""
                                                className={`imgChoose ${
                                                    selectedImages.includes(
                                                        item,
                                                    )
                                                        ? 'selected'
                                                        : ''
                                                }`}
                                                src={modifyURL(item, 500)}
                                                onClick={() =>
                                                    chooseImage(item)
                                                }
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    ) : (
                        <div className="error">
                            <NotFound content={<div>No data to display</div>} />
                        </div>
                    )}
                </div>
            )}
            <div className="footer">
                <Button
                    type="primary"
                    className="btnCancel"
                    onClick={() => {
                        cancelBtn();
                    }}
                    ghost
                >
                    Cancel
                </Button>
                {/* <Spin spinning={isLoading} delay={500}> */}
                <Button type="primary" className="btnSave" onClick={saveEvent}>
                    {'Save'}
                </Button>
                {/* </Spin> */}
            </div>
        </ModalMainImageStyle>
    );
};

export default ModalChoseMainImage;
