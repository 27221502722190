import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo,
    faCoins,
    faPhone,
} from '@fortawesome/free-solid-svg-icons';
import IntroductionGoflTravel from './IntroductionGolfTravel';
import QouteNavigateCompoment from './QuoteNavigate';
import images from 'src/assets/image';
import { nameView } from 'src/const/enum';
import ItineraryInDetailComponent from './ItineraryInDetail';
import PricingAndBookingComponent from './PricingAndBooking';
import ImportantInformationComponent from './ImportantInformation';
import TermsAndCoditionsComponent from './TermsAndCoditions';
import { Button, Skeleton, Spin } from 'antd';
import { updateCheckRoleSuccess } from 'src/redux/actions/roleCheckAction';
import QuoteDetailPageStyle from './style';
import ModalComponent from 'src/components/modalCustom';
import ModalConfirmComponent from './modalConfirm';
import {
    formatCurrency,
    formatPhoneNumber,
    modifyURL,
} from 'src/const/contain';
import QuoteTitleAndInputUpdateComponent from 'src/components/viewQuoteTitle';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import useQueryApi from 'src/hooks/useApiQuery';
import { getImageApi } from 'src/api/apiGetImage';
import { checkChangeApi } from 'src/api/apiCheckChange';
import { getImageContentFromExternalIdApi } from 'src/api/apiGetImageAndContentfFromExternalId';
import { ImageExternalContent } from 'src/types';
import MainImageComponent from 'src/components/mainImageComponent';
import { ModalCopyLink } from './QuoteNavigate/style';
import Search from 'antd/es/input/Search';
import { toast } from 'react-toastify';
import { versionIdSelector } from 'src/redux/selectors/versionIdSelecter';

interface mobileProps {
    Imange?: any;
    dataContenFull?: any;
    dataJsonChild?: any;
    onUpdate?: () => void;
    intinerary?: any;
}

const MobileQouteDetailPage: React.FC<mobileProps> = ({
    Imange,
    dataContenFull,
    dataJsonChild,
    onUpdate,
    intinerary,
}) => {
    const dispatch = useDispatch();
    const [mainImage, setMainImage] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [childData, setChildData] = useState<any>({
        id: nameView.GOFL_TRAVEL_EXPERT,
        content: 'Introduction from your Golf Travel Expert',
    });
    const [loadingGenerate, setLoadingGenerate] = useState<boolean>(false);
    const [generateLinkState, steGenerateLinkState] = useState<boolean>(false);
    const [dataJson, setDataJson] = useState<any>();
    const [roleBtn, setRoleBtn] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModaCopylVisible, setIsModaCopylVisible] = useState(false);

    const [modeModal, setModeModal] = useState<string>('');
    const location: any = useLocation();
    const role: string = location?.state || '';
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const mode = queryParams.get('mode');
    const isRole = useSelector(roleSelector);
    const tourNameTakeImage: string = dataJsonChild?.tourName || '';
    const words = tourNameTakeImage?.split(' ');
    const mainImageAfterUpdate = dataJsonChild?.mainImage || '';
    const externalContent = dataJsonChild?.externalContent || [];
    const firstTwoWords =
        words[0] === 'Masters' ? 'Masters' : `${words[0]} ${words[1]}`;
    const checkEvent = (dataJsonChild as { isEvent: any })?.isEvent;
    const unallocatedAccommodation =
        dataJsonChild?.unallocatedAccommodation || false;
    localStorage.setItem('unallocatedAccommodation', unallocatedAccommodation);
    const promotionalBanner = dataContenFull?.promotionalBanner || '';
    const promotionBannerEvent =
        dataContenFull?.promotionalBannerTournament || '';
    const isEvent = dataJsonChild?.isEvent;
    // const versionId = localStorage.getItem('version_id') || '';
    const versionId = useSelector(versionIdSelector);
    const idQuote = queryParams.get('id');
    const resConsultant = dataJson?.resConsultant;
    const imageDataJson = dataJson?.mainCourse?.image|| null;
    const quoteTitle = dataJson?.title;
    const phoneNumber = dataJson?.resConsultant?.telephone;

    const externalContentId = dataJson?.mainCourse?.externalContentId || '';
    const { data: imageExternalContent } = useQueryApi(
        ['image', externalContentId],
        () => getImageContentFromExternalIdApi(externalContentId),
        {
            enabled: !!externalContentId,
        },
    );

    const exxternalIdImage = (() => {
        const content = imageExternalContent as ImageExternalContent;
        if (
            content?.fields?.cldImages &&
            Array.isArray(content.fields.cldImages) &&
            content.fields.cldImages.length > 0
        ) {
            return content.fields.cldImages[0].url;
        }
        if (
            content?.fields?.images &&
            Array.isArray(content.fields.images) &&
            content.fields.images.length > 0
        ) {
            return content.fields.images[0].url;
        }
        return '';
    })();
    const { data: dataChange, refetch } = useQueryApi(
        ['idCheck'],
        () => checkChangeApi(idQuote),
        {
            enabled: !!type,
        },
    );

    //id image tour event
    const defaultImage = dataContenFull?.defaultImage?.sys?.id;
    const idTheOpenImageInContentFullApi: any =
        dataContenFull?.theOpenImage?.sys?.id || null;
    const idRyderCupImage: any =
        dataContenFull?.theRyderCupImage?.sys?.id || null;
    const idMasterImage: any = dataContenFull?.theMastersImage?.sys?.id || null;
    const idsolheimCupImageImage: any =
        dataContenFull?.solheimCupImage?.sys?.id || null;

    const getMappedArrayByCondition = (condition: any) => {
        let theImageToure = '';
        switch (condition) {
            case 'The Open':
                theImageToure = idTheOpenImageInContentFullApi;
                break;
            case 'Ryder Cup':
                theImageToure = idRyderCupImage;
                break;
            case 'Masters':
                theImageToure = idMasterImage;
                break;
            case 'Solheim Cup':
                theImageToure = idsolheimCupImageImage;
                break;
            default:
                return (theImageToure = defaultImage);
        }
        return theImageToure;
    };
    const idImageTour = getMappedArrayByCondition(firstTwoWords);

    const { data: imageTourTheOpenData } = useQueryApi(
        ['image', idImageTour],
        () => getImageApi(idImageTour),
        {
            enabled: !!idImageTour,
        },
    );

    const imageUrlTourTheOpen = (imageTourTheOpenData as any)?.fields?.file?.url
        ? `http:${(imageTourTheOpenData as any)?.fields?.file?.url}`
        : null;

    useEffect(() => {
        dispatch(updateCheckRoleSuccess('Preview mode'));
        if (imageDataJson) {
            setMainImage(imageDataJson);
        } else {
            setMainImage(Imange);
        }
        setDataJson(dataJsonChild);
    }, [Imange, dataContenFull, dataJsonChild, dispatch, imageDataJson, role]);

    const handleChildDataChange = (data: string) => {
        setChildData(data);
    };

    const changeRoleEdit = () => {
        setRoleBtn(!roleBtn);
        dispatch(updateCheckRoleSuccess('Edit mode'));
    };

    const ModalFinish = (valueChild: boolean) => {
        setRoleBtn(valueChild);
        dispatch(updateCheckRoleSuccess('Preview mode'));
    };

    const showModal = (value?: string) => {
        setIsModalVisible(true);
        if (value) {
            setModeModal(value);
        }
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const sendMail = () => {
        refetch();
        setLoading(true);
        steGenerateLinkState(false);
        setTimeout(() => {
            showModal('send');
            setLoading(false);
        }, 5000);
    };

    const handleCloseModalCopy = () => {
        setIsModaCopylVisible(false);
    };

    const onCopy = () => {
        navigator.clipboard
            .writeText(
                `${process.env.REACT_APP_PUBLIC_ENVIROMENT}/quoteDetail/introduction?id=${versionId}`,
            )
            .then(() => {
                toast.success('Copied to clipboard!');
                setIsModaCopylVisible(false);
                refetch();
            })
            .catch((err) => {
                toast.error('Failed to copy to clipboard. Please try again.');
                setIsModaCopylVisible(false);
            });
    };

    const generateLink = () => {
        refetch();
        if ((dataChange as { changed: any })?.changed === false) {
            setIsModaCopylVisible(true);
            setTimeout(() => {
                setLoadingGenerate(false);
            }, 5000);
        } else {
            setLoadingGenerate(true);
            steGenerateLinkState(true);
            setTimeout(() => {
                showModal('send');
                setLoadingGenerate(false);
            }, 5000);
        }
    };
    // value data json
   

    useEffect(() => {
        if (exxternalIdImage) {
            setMainImage(exxternalIdImage);
        }
    }, [exxternalIdImage, type]);
    return (
        <QuoteDetailPageStyle>
            {mode === 'PreviewMode' && (
                <div className="headerShowRoleMobile">
                    <div className="viewRoleMobile">
                        <div className="viewContentRoleMobile">
                            <span className="styleContentRoleMobile">
                                You're viewing quote in{' '}
                            </span>
                            <span className="styleRoleMobile">{isRole}</span>
                        </div>
                        <div className="viewBtnRoleMobile">
                            {roleBtn && (
                                <Button
                                    type="primary"
                                    className="btnResetMobile"
                                    onClick={() => ModalFinish(false)}
                                    size="small"
                                    ghost
                                >
                                    {'Reset Changes'}
                                </Button>
                            )}
                            {!roleBtn ? (
                                <Button
                                    type="primary"
                                    className="btnEditMobile"
                                    onClick={changeRoleEdit}
                                    size="small"
                                    ghost
                                >
                                    {'Edit'}
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    className="btnEditMobile"
                                    onClick={() => showModal('save')}
                                    size="small"
                                    ghost
                                >
                                    {'Save'}
                                </Button>
                            )}
                            {!roleBtn && (
                                <Spin spinning={loadingGenerate} delay={500}>
                                    <Button
                                        type="primary"
                                        className="btnSendMobile"
                                        style={{ marginRight: '3px' }}
                                        size="small"
                                        onClick={generateLink}
                                    >
                                        Generate link
                                    </Button>
                                </Spin>
                            )}
                            {!roleBtn && (
                                <Spin spinning={loading} delay={500}>
                                    <Button
                                        type="primary"
                                        className="btnSendMobile"
                                        size="small"
                                        onClick={sendMail}
                                    >
                                        Send
                                    </Button>
                                </Spin>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div className="headerPageMobile">
                <div className="logoHeaderMobile">
                    <img
                        alt=""
                        className="LogoIMGMobile"
                        src={images.logoYGT}
                    />
                </div>
                {((isEvent && promotionBannerEvent?.trim()) ||
                    (type === 'Event' && promotionBannerEvent?.trim())) && (
                    <div className="viewPromotionMobile">
                        <div className="contentBtnPromotionMobile">
                            <p>{promotionBannerEvent}</p>
                            <FontAwesomeIcon icon={faCoins} />
                        </div>
                    </div>
                )}
                {((!isEvent && promotionalBanner?.trim()) ||
                    (type === 'Standard' && promotionalBanner?.trim())) && (
                    <div className="viewPromotionMobile">
                        <div className="contentBtnPromotionMobile">
                            <p>{promotionalBanner}</p>
                            <FontAwesomeIcon icon={faCoins} />
                        </div>
                    </div>
                )}
            </div>
            <div className="headerTitleMobile">
                <div className="titleHeaderMobile ">
                    <QuoteTitleAndInputUpdateComponent
                        showRole={isRole}
                        quoteTitle={quoteTitle}
                        onUpdateValueInput={showModal}
                    />
                </div>
                <div className="titleviewMobile">{childData.content}</div>
            </div>
            {(childData.id === nameView.PRICING_AND_BOOKING ||
                childData.id === nameView.GOFL_TRAVEL_EXPERT ||
                childData.id === nameView.IMPORTANT_INFORMATION ||
                childData.id === nameView.TERM_AND_CONDITION) && (
                <div>
                    <div className="viewShowImageMobile">
                        <MainImageComponent
                            checkEvent={checkEvent}
                            type={type}
                            mainImage={
                                mainImageAfterUpdate
                                    ? mainImageAfterUpdate
                                    : mainImage
                            }
                            imageUrlTourTheOpen={
                                mainImageAfterUpdate
                                    ? mainImageAfterUpdate
                                    : imageUrlTourTheOpen
                            }
                            externalContent={externalContent}
                            refetch={onUpdate}
                            roleBtn={ModalFinish}
                            selectedImageOld={mainImageAfterUpdate}
                            refetchDataCheck={refetch}
                        />
                    </div>
                </div>
            )}
            <div className="viewShowInfor">
                <div className="viewInforRight">
                    <QouteNavigateCompoment
                        onDataChange={handleChildDataChange}
                        dataJsonResConsultant={resConsultant}
                        groupData={dataJson}
                        intinerary={intinerary}
                    />
                </div>
                <div className="viewInforLeft">
                    <Switch>
                        <Route exact path="/quoteDetail">
                            <Redirect to="/quoteDetail/introduction" />
                        </Route>
                        <Route
                            path="/quoteDetail/introduction"
                            component={(props: any) => (
                                <IntroductionGoflTravel
                                    {...props}
                                    customProp={dataContenFull}
                                    dataJsonProp={dataJson}
                                    image={mainImage}
                                    onUpdateValueInput={showModal}
                                    refetch={onUpdate}
                                    roleBtn={ModalFinish}
                                    refetchDataCheck={refetch}
                                />
                            )}
                        />
                        <Route
                            path="/quoteDetail/itineraryInDetail"
                            component={(props: any) => (
                                <ItineraryInDetailComponent
                                    {...props}
                                    customProp={dataContenFull}
                                    dataJsonProp={dataJson}
                                    image={mainImage}
                                    onUpdateValueInput={showModal}
                                    refetchValue={onUpdate}
                                    roleBtn={ModalFinish}
                                    refetchDataCheck={refetch}
                                />
                            )}
                        />
                        <Route
                            path="/quoteDetail/pricingAndBooking"
                            component={(props: any) => (
                                <PricingAndBookingComponent
                                    {...props}
                                    dataReadyToBook={dataContenFull}
                                    dataJsonProp={dataJson}
                                    customProp={dataContenFull}
                                    image={mainImage}
                                    refetchDataCheck={refetch}
                                />
                            )}
                        />
                        <Route
                            path="/quoteDetail/importantInformation"
                            component={(props: any) => (
                                <ImportantInformationComponent
                                    {...props}
                                    dataImportanInfor={dataContenFull}
                                    dataJsonProp={dataJson}
                                    image={mainImage}
                                    refetchDataCheck={refetch}
                                />
                            )}
                        />
                        <Route
                            path="/quoteDetail/termsAndCoditions"
                            component={(props: any) => (
                                <TermsAndCoditionsComponent
                                    {...props}
                                    customProp={dataContenFull}
                                    dataJsonProp={dataJson}
                                    image={mainImage}
                                    refetchDataCheck={refetch}
                                />
                            )}
                        />
                        <Route
                            render={() => (
                                <Redirect to="/quoteDetail/introduction" />
                            )}
                        />
                    </Switch>
                </div>
            </div>
            <div className="btnPhone">
                <Button
                    type="primary"
                    className="btnPhoneCall"
                    onClick={() =>
                        (window.location.href = `tel:${
                            phoneNumber || '08000436644'
                        }`)
                    }
                >
                    Call Me On {formatPhoneNumber(phoneNumber || '08000436644')}
                    <FontAwesomeIcon icon={faPhone} /> To Book
                </Button>
            </div>
            <ModalComponent
                width={500}
                visible={isModalVisible}
                onClose={handleClose}
                title="Confirmation"
                content={
                    <ModalConfirmComponent
                        quoteTitle={quoteTitle}
                        close={handleClose}
                        roleBtn={ModalFinish}
                        modeView={modeModal}
                        updateData={onUpdate}
                        dataCheck={dataChange}
                        refetchDataCheck={refetch}
                        generateLinkState={generateLinkState}
                    />
                }
            />
            <ModalComponent
                width={500}
                visible={isModaCopylVisible}
                onClose={handleCloseModalCopy}
                title="Share your quote with your fellow golfers"
                content={
                    <ModalCopyLink>
                        <Search
                            className="inputCopy"
                            placeholder="input search text"
                            value={`${process.env.REACT_APP_PUBLIC_ENVIROMENT}/quoteDetail/introduction?id=${versionId}`}
                            readOnly
                            enterButton="Copy"
                            size="large"
                            onSearch={onCopy}
                        />
                        <div className="contenShare">
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className="faCircleInfo"
                            />
                            Anyone with the link will be able to see this quote
                        </div>
                    </ModalCopyLink>
                }
            />
        </QuoteDetailPageStyle>
    );
};

export default React.memo(MobileQouteDetailPage);
