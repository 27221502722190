import styled from '@emotion/styled';

interface ItineraryInDetailProps {
    mode?: any;
    showEdit?: any;
}

const ItineraryInDetailStyle = styled('div')<ItineraryInDetailProps>`
    width: 100%;
    position: relative;
    bottom: 6px;
    page-break-inside: avoid;

    ul {
        list-style-type: disc;
        padding-left: 20px;
        margin: 0;
    }

    ol {
        list-style-type: lower-alpha;
        padding-left: 20px;
        margin: 0;
    }

    li {
        margin: 5px 0;
    }
    .viewMainImageCarosel {
        width: 100%;
        height: 413px;
        margin-bottom: 23px;
        border-radius: 8px;
        border: ${(props) =>
            props.mode === 'Edit mode' ? '2px solid #12b897' : 'none'};
    }
    .ant-carousel .slick-dots-bottom{
        bottom: 30px;
    }
    .imgGoflTravelcarosel {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
    }
    @media screen and (max-width: 1200px) {
        .viewMainImageCarosel {
            width: 100%;
            height: 170px;
            margin-bottom: 16px;
            border-radius: 8px;
            border: ${(props) =>
                props.mode === 'Edit mode' ? '2px solid #12b897' : 'none'};
        }
        .imgGoflTravelcarosel {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 6px;
        }
    }
    .btnChoseImage {
        border-radius: 6px;
        border: 2px solid #12b897;
        background: #fff;
        color: #12b897;
        margin-bottom: 16px;
    }
    .btnChoseImage:hover {
        border-radius: 6px;
        border: 2px solid #12b897 !important;
        background: #fff;
        color: #12b897 !important;
    }
    .container {
        page-break-inside: avoid;
    }
    .viewAddtionEdit {
        position: relative;
    }
    .addtionEdit {
        margin-bottom: 20px;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        background: ${(props) =>
            props.mode === 'Edit mode' ? '#1D3254' : 'none'};
        border: ${(props) =>
            props.mode === 'Edit mode' ? '2px solid #12b897' : 'none'};
    }
    .iconAddCommentAddconditional {
        width: 100%;
        display: ${(props) => (props.mode === 'Edit mode' ? 'flex' : 'none')};
        justify-content: flex-end;
        position: absolute;
        top: ${(props) => (props.showEdit ? '59px' : '59px')};
        left: 15px;
        cursor: pointer;
    }
    .iconAdd {
        width: 35px;
        height: 35px;
    }
    .iconAdd:hover {
        transform: scale(1.1);
    }
    @media screen and (max-width: 768px) {
        position: unset;
        /* bottom: 20px; */
    }
    .hotelAndGoflView {
        border-radius: 4px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        display: flex;
        justify-content: space-between;
    }
    .imgHotelOrGofl {
        width: 171px;
        height: 171px;
        gap: 16px;
        border-radius: 6px;
    }
    .error {
        margin-top: 45px;
        border-radius: 4px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
    }
    .titleItem {
        color: #000;
        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 12px;
    }
    .addressHotelOrGolf {
        color: #000;
        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 16px;
        font-weight: 500;
    }
    .contentInfor {
        color: #000;
        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 14px;
        margin-top: 15px;
        line-height: 20px;
    }
    .discriptHotel {
        color: #4f4f4f;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16px;
    }
    .iconHotelorGolf {
        width: 22px;
        height: 22px;
    }
    .iconAndBtnItem {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
    }
    .btnSeeDetail {
        color: #1a91ff;
        text-align: right;
        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 16px;
        font-weight: 700;
        text-decoration-line: underline;
        cursor: pointer;
    }
    .titleDay1 {
        color: #000;
        font-size: 22px;
        font-weight: 900;
        margin-bottom: 16px;
    }
    .reviewSeller {
        display: flex;
        padding: 34px 111px 34px 111px;
        align-items: center;
        gap: 83px;
        border-radius: 4px;
        background: #f0fffe;
        margin-bottom: 20px;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    }
    .avatarSeller {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .avatar {
        width: 87.065px;
        height: 89px;
        color: #12b897;
    }
    .pleaseRead {
        color: #000;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0.24px;
        margin-bottom: 6px;
    }
    .contentPleaseRead {
        color: #000;
        font-size: 20px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0.2px;
        margin-bottom: 20px;
    }
    .discriptionPleaseRead {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0.14px;
        list-style-type: disc;
    }
    ul {
        list-style-type: disc;
        padding-left: 20px;
        margin: 0;
    }

    ol {
        list-style-type: lower-alpha;
        padding-left: 20px;
        margin: 0;
    }

    li {
        margin: 5px 0;
    }

    .viewDiscriptionPleaseRead {
        padding-left: 24px;
        padding-right: 24px;
    }
    .viewPleaseRead {
        border-radius: 4px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
    }
    .whyBookWithYGTComponent {
        page-break-inside: avoid;
        border-radius: 4px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        margin-bottom: 27px;
    }
    .iconCheck {
        color: #12b897;
        margin-right: 11px;
        margin-top: 5px;
    }
    .discriptionWBW {
        display: flex;
        align-items: flex-start;
        margin-top: 20px;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 22.4px;
    }
    .titlePleaseRead {
        margin-bottom: 10px;
        margin-top: 10px;
        font-weight: 700;
        font-size: 20px;
        position: relative;
        right: 15px;
    }
`;

export default ItineraryInDetailStyle;

export const ErrorStyle = styled('div')`
    border-radius: 4px;
    background: #fff;
    box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    padding: 24px;
`;
