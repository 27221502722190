import React, { useEffect, useState } from 'react';
import ModalMainImageStyle from './style';
import useQueryApi from 'src/hooks/useApiQuery';
import { getListImage } from 'src/api/apiGetListImage';
import { modifyURL } from 'src/const/contain';
import NotFound from 'src/components/notFoundData';
import { Button, Select } from 'antd';
import { useMutationApi } from 'src/hooks/useApiMutation';
import { apiUpdateQuote } from 'src/api/apiUpdateQuote';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { getCountry } from 'src/api/apiGetCountry';
import { getTournamentnament } from 'src/api/apiGetTour';

interface ModalMainImageProps {
    externalContent?: any;
    close?: () => void;
    refetch?: () => void;
    roleBtn?: (value: any) => void;
    selectedImageOld?: any;
    refetchDataCheck?:() =>void
}

const ModalChoseMainImage: React.FC<ModalMainImageProps> = ({
    externalContent,
    close,
    refetch,
    roleBtn,
    selectedImageOld,
    refetchDataCheck
}) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [tab, setTab] = useState<string>('itinareryTab');
    const [countryValue, setCountryValue] = useState<any>('');
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const idQuote = queryParams.get('id');
    const type = queryParams.get('type');

    const resultEntryId =
        externalContent
            ?.map((entryId: any) => `entryId=${entryId}`)
            ?.join('&') || '';

    const { data: DataMainImage, refetch: refetchMainImage } = useQueryApi(
        ['image', externalContent],
        async () => {
            try {
                if (tab === 'itinareryTab') {
                    return await getListImage(resultEntryId);
                } else {
                    return await getListImage(countryValue);
                }
            } catch (error) {
                return null;
            }
        },
        {
            enabled: !!externalContent && !!tab,
            onError: (error:any) => {
                console.error('Error fetching images:', error);
            },
        }
    );
    

    useEffect(() => {
        setSelectedImage(selectedImageOld);
        if(type === 'Event'){
            setTab('countryTab');
        }

        if (countryValue) {
            refetchMainImage();
        }

    }, [countryValue, refetchMainImage, selectedImageOld, type]);

    const { data: DataTournament } = useQueryApi(['Tournament'], () =>
        getTournamentnament(),
    );

    const { data: DataCountry } = useQueryApi(['country'], () => getCountry());

    const selectValueTournament = Array.isArray(DataTournament)
        ? DataTournament.map((Tour) => ({
              value: Tour.id,
              label: Tour.name,
          }))
        : [];

    const selectValue = Array.isArray(DataCountry)
        ? DataCountry.map((country) => ({
              value: country.id,
              label: country.name,
          }))
        : [];

    const { mutate } = useMutationApi<{ id: any; dataParam: any }, any>(
        ({ id, dataParam }) => apiUpdateQuote(id, dataParam),
        {
            onSuccess: (response) => {
                toast.success(`Save image successfully!`);
                setSelectedImage(null);
                refetch?.();
                roleBtn?.(false);
                close?.();
                refetchDataCheck?.()
            },
            onError: (error) => {
                toast.error(`Save image false!`);
                setSelectedImage(null);
                close?.();
            },
        },
    );

    const itinareryTab = () => {
        setTab('itinareryTab');
        refetchMainImage()
        setCountryValue('')
    };

    const countryTab = () => {
        setTab('countryTab');
        refetchMainImage();
        setCountryValue('')
    };

    const chooseImage = (image: string) => {
        if (selectedImage === image) {
            setSelectedImage(null);
        } else {
            setSelectedImage(image);
        }
    };

    const saveEvent = () => {
        const data = {
            mainImage: selectedImage,
        };
            mutate({ id: idQuote, dataParam: data });
        
    };

    const cancelBtn = () => {
        setSelectedImage(null);
        refetchMainImage();
        setTab('itinareryTab');
        close?.();
    };

    const onChange = (value: string) => {
        const paramCountry = `countryId=${value}`;
        const paramTour = `tournamentId=${value}`;

        setCountryValue(type === 'Event' ? paramTour : paramCountry);
    };

    return (
        <ModalMainImageStyle>
            <div className="groupTab">
                {type === 'Standard' && (
                    <div
                        className={`itineraryTab ${
                            tab === 'itinareryTab' ? 'focusTab' : ''
                        }`}
                        onClick={itinareryTab}
                    >
                        Itinerary Image
                    </div>
                )}
                <div
                    className={`countryTab ${
                        tab === 'countryTab' ? 'focusTab' : ''
                    }`}
                    onClick={countryTab}
                >
                    {type === 'Standard' ? 'Country Image' : 'Tournament Image'}
                </div>
                {(tab === 'countryTab' || type === 'Event') && (
                    <div className="viewSelect">
                        <Select
                            className="selectCountry"
                            // value={''}
                            placeholder={
                                type === 'Event'
                                    ? 'Select Tournament'
                                    : 'Select country'
                            }
                            optionFilterProp="label"
                            onChange={onChange}
                            options={
                                type === 'Event'
                                    ? selectValueTournament
                                    : selectValue
                            }
                        />
                    </div>
                )}
            </div>
            {tab === 'itinareryTab' ? (
                <div>
                    {Array.isArray(DataMainImage) ? (
                        <div className="viewListImage scroll-container">
                            {Array.isArray(DataMainImage) &&
                                DataMainImage.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <img
                                                alt=""
                                                className={`imgChoose ${
                                                    selectedImage === item
                                                        ? 'selected'
                                                        : ''
                                                }`}
                                                src={modifyURL(item, 500)}
                                                onClick={() =>
                                                    chooseImage(item)
                                                }
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    ) : (
                        <div className="error">
                            <NotFound content={<div>No data to display</div>} />
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    {Array.isArray(DataMainImage) ? (
                        <div className="viewListImage scroll-container">
                            {Array.isArray(DataMainImage) &&
                                DataMainImage.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <img
                                                alt=""
                                                className={`imgChoose ${
                                                    selectedImage === item
                                                        ? 'selected'
                                                        : ''
                                                }`}
                                                src={modifyURL(item, 500)}
                                                onClick={() =>
                                                    chooseImage(item)
                                                }
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    ) : (
                        <div className="error">
                            <NotFound content={<div>No data to display</div>} />
                        </div>
                    )}
                </div>
            )}
            <div className="footer">
                <Button
                    type="primary"
                    className="btnCancel"
                    onClick={() => {
                        cancelBtn();
                    }}
                    ghost
                >
                    Cancel
                </Button>
                {/* <Spin spinning={isLoading} delay={500}> */}
                <Button type="primary" className="btnSave" onClick={saveEvent}>
                    {'Save'}
                </Button>
                {/* </Spin> */}
            </div>
        </ModalMainImageStyle>
    );
};

export default ModalChoseMainImage;
