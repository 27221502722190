import styled from '@emotion/styled';

interface PricingAndBookingStyleProps {
    mode?: any;
}

const PricingAndBookingStyle = styled('div')<PricingAndBookingStyleProps>`
    width: 100%;
    border-radius: 8px;
    background: #fff;
    box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    padding: 48px 37px 60px 37px;
    display: flex;
    flex-direction: column;
    /* page-break-inside: avoid; */

    .areaTitleQuote {
        margin-bottom: 24px;
        /* width: 45%; */
    }
    .titleQuote {
        color: #1a91ff;
        text-align: center;
        font-size: 18.432px;
        font-weight: 700;
        line-height: 33.016px;
        border-radius: 6.349px;
        border: 2.304px solid #1a91ff;
        padding: 4px 10px 4px 10px;
    }
    .viewItemPricing {
        page-break-inside: avoid;
    }
    .quoteReferent {
        color: #000;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
    }
    .headerGroup {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
    }
    .fullNameGroup {
        display: flex;
    }
    .firstNameGroup {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        line-height: 37px;
        margin-right: 7px;
    }
    .lastNameGroup {
        color: #000;
        font-size: 20px;
        font-weight: 400;
        line-height: 37px;
    }
    .priceGroup {
        color: #454545;
        text-align: right;
        font-size: 20px;
        font-weight: 900;
        line-height: 24px;
    }
    .contentInfor {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        line-height: 20px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
    }
    .bodyGroup {
        padding-left: 60px;
        padding-right: 60px;
    }
    .iconOfGroup {
        color: #12b897;
        margin-right: 10px;
    }
    .areaGroup {
        margin-bottom: 24px;
    }
    .areaEnd {
        width: 100%;
        border-top: 1px solid #dcdcdc;
        padding: 21px 0px 0px 0px;
    }
    .viewTotalPrice {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
    }
    .contentTotalPrice {
        color: #000;
        font-size: 18.657px;
        font-weight: 700;
        line-height: 21.456px;
    }
    .priceTotal {
        color: #454545;
        text-align: right;
        font-size: 22.389px;
        font-weight: 900;
        line-height: 22.389px;
    }
    .contentTotalDeposit {
        color: #000;
        font-size: 16px;
        font-weight: 700;
        line-height: 21.456px;
    }
    .priceDeposit {
        color: #454545;
        text-align: right;
        font-size: 16px;
        font-weight: 900;
        line-height: 22.389px;
    }
    .areaChoosen {
        width: 100%;
        padding: 54px 64px 84px 64px;
        background: #f0fffe;
        margin-bottom: 40px;
        margin-top: 20px;
    }
    .titleAreaChoosen {
        color: #000;
        font-size: 24px;
        font-weight: 900;
        line-height: 28px;
        margin-bottom: 14px;
    }
    .contentAreaChoosen {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 34px;
    }
    .btnEnd {
        width: 100%;
        background: #12b897;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 17.121px;
        font-family: 'Lato';
        font-weight: 900;
        line-height: 15.295px;
        border-radius: 6px;
        padding: 20px 0px 20px 20px;
        cursor: pointer;
    }
    .btnLink {
        color: #fff;
    }
    @media print {
        .areaChoosen {
            background: #f0fffe !important;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
        }
        .btnEnd {
            width: 100%;
            background: #12b897 !important;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff !important;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
            font-size: 17.121px;
            font-family: 'Lato';
            font-weight: 900;
            line-height: 15.295px;
            border-radius: 6px;
            padding: 20px 0px 20px 20px;
            cursor: pointer;
        }
        .btnLink {
            color: #fff !important;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
        }
    }
    .btnEnd:hover {
        transform: scale(1.005);
    }
    .imageGoftLogo {
        width: 60%;
    }
    .areaLogo {
        display: flex;
        align-items: center;
        justify-content: center;
        page-break-after: avoid
    }
    .discount {
        color: #1877f2;
    }
    .viewEditMode {
        border: ${(props) =>
            props.mode === 'Edit mode' ? '2px solid #12b897' : 'none'};
        padding: ${(props) => (props.mode === 'Edit mode' ? '16px' : '0px')};
    }
    .viewEditpricing {
        position: relative;
        margin-bottom: 20px;
    }
    .iconAddComment {
        width: 100%;
        display: ${(props) => (props.mode === 'Edit mode' ? 'flex' : 'none')};
        justify-content: flex-end;
        position: absolute;
        top: 130px;
        left: 15px;
        cursor: pointer;
    }
    .iconAdd {
        width: 35px;
        height: 35px;
    }
    .iconAdd:hover {
        transform: scale(1.1);
    }
    @media screen and (max-width: 768px) {
        padding: 16px 20px 16px 20px;
        .titleQuote {
            font-size: 17px;
        }
        .bodyGroup {
            padding-left: 0px;
            padding-right: 0px;
        }
        .iconOfGroup {
            margin-right: 10px;
        }
        .areaTitleQuote {
            margin-bottom: 24px;
            width: 80%;
        }
        .areaChoosen {
            padding: 0px;
            background: #fff;
        }
        .btnEnd {
            font-size: 15px;
            justify-content: center;
            font-family: 'Lato';
        }
    }
    @media screen and (max-width: 455px) {
        .areaTitleQuote {
            margin-bottom: 24px;
            width: 100%;
        }
        .titleQuote {
            font-size: 14px;
        }
        .firstNameGroup {
            color: #000;
            font-size: 14px;
            font-weight: 700;
            line-height: 37px;
            margin-right: 7px;
        }
        .lastNameGroup {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            line-height: 37px;
        }
        .quoteReferent {
            color: #000;
            font-size: 20px;
            font-weight: 700;
            line-height: 29px;
        }
    }
`;
export default PricingAndBookingStyle;

export const PricingAndBookingMainImageStyle = styled('div')`
    width: 100%;
    border-radius: 6px;
    .viewMainImage {
        width: 100%;
        height: 413px;
        margin-bottom: 23px;
    }
    .imgGoflTravel {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
    }
    @media screen and (max-width: 1200px) {
        .viewMainImage {
            display: none;
        }
    }
`;
