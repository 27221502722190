import styled from '@emotion/styled';

const IntineraryStandardStyle = styled('div')`
    .iconHotelorGolf {
        color: #12b897;
    }
    .iconDetail {
        color: #12b897;
    }
    .imageEvent {
        width: 100%;
        height: 223px;
        border-radius: 8px;
        object-fit: cover;
    }
    .nameEventTicket {
        background-color: #eef9ff;
        border-radius: 8px;
        /* height: 120px; */
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        margin-bottom: 20px;
    }
    .headerTicket {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .bodyTicket {
        margin-left: 20px;
    }
    .contentEvent {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0.14px;
        list-style-type: disc;
    }
    .iconCup {
        color: #12b897;
    }
    .dateBaneComent {
        padding: 15px 23px 15px 23px;
        background-color: #eef9ff;
        border-radius: 8px;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        margin-bottom: 16px;
        min-height: 100px;
        font-size: 20px;
        font-weight: 700;
        line-height: 24.04px;
        text-align: left;
    }
`;

export default IntineraryStandardStyle;
